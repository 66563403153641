<template>
  <b-card>
    <b-row class="table-filter">
      <!-- filter date from -->
      <b-col cols="2">
        <b-form-group
          id="input-from-date"
          label="Từ Ngày"
          label-for="from-date"
        >
          <datePicker v-model="filter_from" :config="dpOptions"></datePicker>
        </b-form-group>
      </b-col>

      <!-- filter date to -->
      <b-col cols="2">
        <b-form-group id="input-to-date" label="Đến Ngày" label-for="to-date">
          <datePicker v-model="filter_to" :config="dpOptions"></datePicker>
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group
          id="input-status-filter"
          label="Trạng Thái"
          label-for="status-filter"
        >
          <b-form-select
            id="status-filter"
            v-model="filter_status"
            :options="statusList"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">Tất cả</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>

      <b-col cols="2"> </b-col>

      <b-col cols="2">
        <b-button
          block
          variant="success"
          class="search-btn"
          @click="handleSearch"
          >TÌM KIẾM</b-button
        >
      </b-col>
      <b-col cols="2">
        <b-button
          block
          variant="primary"
          class="search-btn"
          @click="handleExport"
          >XUẤT EXCEL</b-button
        >
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <div class="col-md-4">
        <b-card class="border-primary">
          <h5 class="text-primary">TỔNG HOA HỒNG</h5>
          <h1 class="text-primary">
            {{ formatPrice(summary.total_commission || 0, "") }}
          </h1>
        </b-card>
      </div>
      <div class="col-md-4">
        <b-card class="border-success">
          <h5 class="text-success">ĐÃ NHẬN</h5>
          <h1 class="text-success">
            {{ formatPrice(summary.total_received || 0) }}
          </h1>
        </b-card>
      </div>
      <div class="col-md-4">
        <b-card class="border-warning">
          <h5 class="text-warning">CÒN LẠI</h5>
          <h1 class="text-warning">
            {{ formatPrice(summary.total_remain || 0) }}
          </h1>
        </b-card>
      </div>
    </b-row>
    <span class="text-muted"
      >Hoa hồng đối tác sẽ được đối soát và chuyển vào mỗi cuối tháng</span
    >
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      small
      class="elevation-1 data-table text-center"
    >
      <template v-slot:cell(created_at)="item">{{
        formatDate(item.value, "YYYY-MM-DD")
      }}</template>
      <template v-slot:cell(amount)="item">{{
        formatPrice(item.value)
      }}</template>
      <template v-slot:cell(bank_name)="item"
        >{{ item.value }}<br />
        {{ item.item.bank_number }}<br />
        {{ item.item.bank_account }}</template
      >
      <template v-slot:cell(status)="item" :v-if="item.value == 1">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success"
          >Hoàn Tất</b-badge
        >
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning"
          >Chờ Xác Nhận</b-badge
        >
        <b-badge class="mr-1" v-else-if="item.value === 2" variant="danger"
          >Đã Huỷ</b-badge
        >
      </template>
    </b-table>
    <b-row>
      <b-col cols="3">
        Hiển thị {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        trong {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form inline style="margin-right: 20px; margin-bottom: 1rem">
          <label class="mr-sm-2" for="per-page">Số dòng: </label>
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            @change="fetchData"
          ></b-form-select>
        </b-form>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import datePicker from "vue-bootstrap-datetimepicker";
import ApiService from "@/core/services/api.service.js";
// import axios from "axios";
export default {
  name: "Commission",
  components: { datePicker },
  data() {
    return {
      isLoading: true,
      isFilterByGame: true,
      search: "",
      isBusy: false,
      statusList: [
        { value: 1, text: "Hoàn Tất", variant: "success" },
        { value: 2, text: "Đã Huỷ", variant: "danger" },
        { value: 0, text: "Chờ Xác Nhận", variant: "warning" },
      ],
      gameList: [],
      paymentMethodList: [],
      headers: [
        { label: "Ngày Nhận", key: "created_at" },
        { label: "Số Tiền Nhận", key: "amount" },
        { label: "Tài Khoản Nhận Tiền", key: "bank_name" },
        { label: "Trạng Thái", key: "status" },
      ],
      // Modal data
      items: [],
      modalTitle: "",
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      dialog: false,
      dpOptions: {
        format: "MM/DD/YYYY",
        sideBySide: true,
      },
      timeout: null,
      filter_from: "",
      filter_to: "",
      username: "",
      invite_code: "",
      root_inviter_code: "",
      filter_status: null,
      summary: {},
    };
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Hoa hồng" }]);
  },
  computed: {
    campaignId() {
      return this.$store.state.auth.campaignId;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    },
    campaignId() {
      this.fetchData();
    },
  },
  methods: {
    handleExport() {
      this.exportData("/partners/exports/commissions", this.getQueryParams());
    },
    handleSearch() {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }

      this.timeOut = setTimeout(() => {
        this.fetchData();
      }, 400);
    },
    getQueryParams() {
      let to = "";
      if (this.filter_to) {
        to = new Date(this.filter_to);
        to.setDate(to.getDate() + 1);
      }
      return {
        from: this.filter_from ? new Date(this.filter_from) : "",
        to,
        limit: this.perPage,
        offset: this.currentPage,
        status: this.filter_status,
      };
    },
    fetchData() {
      ApiService.query("/partners/commissions", this.getQueryParams()).then(
        (response) => {
          this.items = response.data.data.commissionList;
          this.summary = response.data.data.summary;
          this.rows = response.data.data.total;
          this.isBusy = false;
        }
      );
    },
  },
};
</script>
